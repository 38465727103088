.header {
    @include alternative-spacing;

    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    a {
        color: inherit;
    }

    &--logo {
        width: 233px;
        height: 63px;
    }

    &--toggler {
        .navbar-toggler {
            border: none;
            box-shadow: none;
            outline: none;
        }

        .hamburger-green {
            background-image: url(#{$pathToImages}/hamburger-green.svg);
        }

        .hamburger-white {
            background-image: url(#{$pathToImages}/hamburger-white.svg);
        }
    }

    &--nav {
        @include media-breakpoint-down (lg) {
            @include ease;
            display: block;
            z-index: 2;
            opacity: 0;
        }

        &.show {
            opacity: 1;
        }

        &--logo {
            width: 233px;
            height: 63px;
            display: none;

            @include media-breakpoint-down (lg) {
                display: block;
            }

            &-wrapper {
                padding: 32px 24px;

                .btn-close {
                    display: none;

                    @include media-breakpoint-down (lg) {
                        display: block;
                        opacity: 1;
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }

        &--item {
            @include ease;

            padding-top: 15px;

            @include media-breakpoint-down (lg) {
                padding: 32px 24px 0 24px;
            }

            .text-white {
                color: $white;
            }

            .text-colour {
                color: $text-green;

                @include media-breakpoint-down (lg) {
                    color: $white;
                }
            }

            .nav-link {
                @include ease;

                font-weight: 600;
                display: inline-block;
                border-bottom: 2px solid transparent;

                &.active-link,
                &:hover,
                &:active,
                &:focus {
                    border-bottom-color: $green-link-hover;
                    outline: none;
                }

                &:focus {
                    color: $green-link-hover;
                }
            }


        }

        .navbar-nav {
            @include media-breakpoint-down (lg) {
                position: fixed;
                height: 100vh;
                max-width: 100%;
                width: 480px;
                right: 0;
                top: 0;
                background-color: $text-green;
                z-index: 2;
                outline: 9999px solid rgba(0, 0, 0, 0.5);
            }
        }
    }

    @include media-breakpoint-down (lg) {
        :nth-child(1 of .nav-item) {
            padding-top: 48px;
        }
    }

    .nav-item:not(:last-of-type) {
        @include ease;

        padding-right: 48px;

        @include media-breakpoint-down (xl) {
            padding-right: 24px;
        }
    }
}

// change body background when navbar is open
.nav-open {
    background: rgba(255, 255, 255, 0.3);
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.show {
    opacity: 1;
}
