.factsheets {
    &--factsheet {
        @include ease;

        position: relative;
        border-radius: $half-border-radius;
        overflow: hidden;
        margin-bottom: 24px;

        &-link {
            width: 100%;
        }

    }

    &--title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
        z-index: 1;
        color: $white;


        &--chevron {
            @include link-chevron;
            @include ease;

            margin-left: 8px;
            background-image: url($iconChevronWhite);

            a:hover &,
            button:hover & {
                transform: translateX(3px);
                background-image: url($iconChevronWhite) !important;
            }
        }
    }

    &--image {
        position: relative;
        width: calc(100% - 64px);

        @include media-breakpoint-up (xl) {
            width: calc(100% - 80px);
        }

        img {
            @include ease-out;

            a:hover & {
                opacity: 0.5;
                transform: scale(1.05, 1.05);

                @include media-breakpoint-up (sm) {
                    transform: scale(1.1, 1.1);
                }
            }
        }

        &-wrapper {
            @include ease;

            background-color: $text-green;
            padding: 32px 32px 0 32px;

            @include media-breakpoint-up (sm) {
                padding: 32px;
            }

            @include media-breakpoint-up (xl) {
                padding: 40px;
            }

        }

        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(4, 61, 42);
            background: linear-gradient(0deg, rgba(4, 61, 42, 1) 0%, rgba(4, 61, 42, 1) 15%, rgba(4, 61, 42, 0) 100%);
        }
    }
}
