.checklist-and-timeline {
    @include standard-spacing;

    &--box {
        background-color: $light-green-background;
        border-radius: $standard-border-radius;
        border: 1px solid $bright-green;
        margin-top: 60px;
        padding: 48px 24px;

        @include media-breakpoint-up(md) {
            padding: 80px 83px;
        }

        &--heading {
            color: $text-green;
            font-style: italic;
            font-weight: 300;
            font-size: 30px;
            padding-bottom: 3rem;

            @include media-breakpoint-up(lg) {
                padding-bottom: 5rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 36px;
                padding-bottom: 6rem;
            }
        }

        &--checklist {
            border-radius: $standard-border-radius;
            background-color: $white;
            padding: 40px;
            margin-bottom: 30px;

            &--text {
                margin-left: 20px;
            }
        }
    }
}
