.block--heading-text-and-cta {
    @include standard-spacing;

    &--heading {
        margin-bottom: 2rem;
    }

    &--text {
        color: $dark-grey;

        a {
            text-decoration: none;
            color: $dark-grey;
        }
    }
}
