.full-width-image-and-inset-text {
    @include standard-spacing;
    position: relative;

    &--text-box {
        background-color: $text-green;
        color: $white;
        border-radius: $standard-border-radius;
        position: relative;
        padding: 48px 28px;
        top: -100px;

        @include media-breakpoint-down(xl) {
            top: -30px;
        }

        &--heading {
            color: $white;
        }
    }
}
