.who-are-we {
    @include standard-spacing;

    a {
        text-decoration: none;
        color: $text-green;
        font-weight: 400;
    }

    a:hover {
        color: $green-link-hover;
    }

    .margin {
        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--images {
        height: 100%;
        margin-bottom: 48px;

        &--logo {
            background: $text-green;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            width: 100%;
            height: 84px;

            @include media-breakpoint-up (sm) {
                height: 150px;
            }

            @include media-breakpoint-up (md) {
                height: 128px;
            }

            @include media-breakpoint-up (lg) {
                height: 211px;
            }

            @include media-breakpoint-up (lg) {
                height: 206px;
            }

            @include media-breakpoint-up (xl) {
                width: 58%;
                margin-right: 16px;
                height: auto;
            }

            &--image {
                width: 166px;

                @include media-breakpoint-up (sm) {
                    width: 300px;
                }

                @include media-breakpoint-up (md) {
                    width: 50%;
                }

                @include media-breakpoint-up (lg) {
                    width: 226px;
                }

                @include media-breakpoint-up (xl) {
                    width: 317px;
                }
            }
        }

        &--item {
            margin-bottom: 16px;

            img {
                width: 100%;
            }

            &:nth-child(2) {
                width: calc(50% - 8px);
                margin-right: 16px;

                @include media-breakpoint-up (xl) {
                    width: 39%;
                    margin-right: 0px;
                }
            }

            &:nth-child(3) {
                width: calc(50% - 8px);

                @include media-breakpoint-up (xl) {
                    width: 39%;
                    margin-right: 16px;
                }
            }

            &:nth-child(4) {
                display: none;

                @include media-breakpoint-up (xl) {
                    width: 58%;
                    display: block;
                }
            }
        }


        &--logo,
        &--item img {
            border-radius: $standard-border-radius;
        }
    }

    &--content {

        &--text {
            color: $black;
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 30px;
            font-weight: 300;
        }

        &--callout {
            color: $text-green;
            font-size: 20px;
            font-style: italic;
            margin-bottom: 30px;
            font-weight: 300;
        }

        &--link {
            text-decoration: none;
            color: $text-green;
            font-weight: 300;
        }
    }
}
