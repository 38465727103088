.block--accordion {
    @include standard-spacing;

    &--accordion {
        z-index: 1;
    }

    &--heading {
        color: $text-green;
    }

    &--item {
        border: none;

        &--title {
            color: $text-green;
            font-weight: 500;
            background-color: $light-green-background;
            font-size: 15px;

            @include media-breakpoint-up (sm) {
                font-size: 20px;
            }

            &:after {
                background-image: url(#{$pathToImages}/expand.svg);
                display: inline-block;
                background-size: 24px;
                width: 24px;
                height: 24px;

                @include media-breakpoint-up (sm) {
                    background-size: 32px;
                    width: 32px;
                    height: 32px;
                }
            }

            &:not(.collapsed) {
                background: $text-green;
                color: $white;
                box-shadow: none;

                &:after {
                    background-image: url(#{$pathToImages}/collapse.svg);
                    display: inline-block;
                    transform: none;
                    transition: none;
                    background-size: 24px;
                    width: 24px;
                    height: 24px;

                    @include media-breakpoint-up (sm) {
                        background-size: 32px;
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            &:focus {
                box-shadow: none;
            }

            &:not(.collapsed) {
                background: $text-green;
                color: $white;
            }
        }

        &--text {
            font-size: 15px;
            color: $white;
            background-color: $text-green;
            border-radius: 0 0 16px 16px;
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .accordion-flush .accordion-item .accordion-button {
        border-radius: 16px 16px 0 0;
    }

    .accordion-flush .accordion-item .accordion-button.collapsed {
        border-radius: 16px;
    }
}
