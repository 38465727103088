.block--icon-and-text {

    &--icon,
    &--text {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    &--icon {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
}
