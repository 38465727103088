.block--related-factsheets {
    @include standard-spacing;

    &--text-content {
        margin-bottom: 24px;

        @include media-breakpoint-up (xl) {
            margin-bottom: 40px;
        }

    }

    &--text {
        margin-bottom: 20px;

        @include media-breakpoint-up (xl) {
            margin-bottom: 24px;
        }
    }
}
