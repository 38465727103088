* {
    font-family: museo-sans-rounded, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;

    font-weight: 300;
}

html {
    scroll-behavior: auto !important;
}

body {
    min-width: 320px;
    scroll-behavior: smooth;
    position: relative;
    overflow-x: hidden;

    &.scroll-reveals {
        $zease: cubic-bezier(0.165, 0.84, 0.44, 1);

        [data-scroll] {
            visibility: hidden;
            will-change: transform, opacity;
            transition: opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s,
                transform 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;
        }

        [data-scroll],
        [data-scroll="out"] {
            opacity: 0;
            // Move down.
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
        }

        [data-scroll="out"] {
            visibility: visible;
        }

        [data-scroll="in"] {
            visibility: visible;
            opacity: 1;
            // Move up.
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }
    }
}

.logo {
    @include media-breakpoint-down (xl) {
        width: 168px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.favicon-icon,
.logo-text {
    display: inline-block;
    background-repeat: no-repeat;
}

.favicon-icon {
    background-image: url(/theme/images/favicon.ico);
    background-size: 29px 30px;
    width: 29px;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 12px;

    @include media-breakpoint-up (lg) {
        background-size: 41px 42px;
        width: 41px;
        height: 42px;
        margin-bottom: 15px;
    }
}

.logo-text {
    background-image: url(/theme/images/logo-text.svg);
    background-size: 124px 46px;
    width: 124px;
    height: 46px;

    @include media-breakpoint-up (lg) {
        background-size: 173px 63px;
        width: 173px;
        height: 63px;
    }
}
