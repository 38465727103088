.block--media-releases {
    @include standard-spacing;

    .releases {
        margin-top: 48px;

        &--date {
            color: $text-green;
            font-size: 15px;
            font-style: italic;
            font-weight: 400;
            line-height: 1.2;
            margin-bottom: 20px
        }

        &--heading {
            color: $text-green;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.5;

            &--chevron {
                @include link-chevron-large;
                @include ease;

                margin-left: 8px;
                margin-bottom: -7px;
            }
        }

        &--link {
            text-decoration: none;

            &:hover .releases--heading--chevron {
                transform: translateX(6px);
            }
        }

        .carousel-desktop .carousel--track {
            margin-bottom: 48px;
        }

        .carousel-mobile .releases--release {
            margin-bottom: 48px;
        }
    }
}
