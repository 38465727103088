@mixin standard-spacing {
    margin-block-end: 4rem;

    @include media-breakpoint-up (sm) {
        margin-block-end: 5rem;
    }

    @include media-breakpoint-up (lg) {
        margin-block-end: 6rem;
    }

    @include media-breakpoint-up (xl) {
        margin-block-end: 8rem;
    }
}

@mixin alternative-spacing {
    margin-block-end: 4rem;

    @include media-breakpoint-up (sm) {
        margin-block-end: 5rem;
    }

    @include media-breakpoint-up (lg) {
        margin-block-end: 6rem;
    }
}

@mixin full-light-green-background-padded {
    background-color: $light-green-background;

    padding-top: 64px;
    padding-bottom: 64px;

    @include media-breakpoint-up (sm) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include media-breakpoint-up (lg) {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    @include media-breakpoint-up (xl) {
        padding-top: 128px;
        padding-bottom: 128px;
    }
}

@mixin link-chevron {
    background-size: 8px 14px;
    width: 8px;
    height: 12px;
    display: inline-block;
    background-image: url($iconChevronDefault);

    a:hover &,
    button:hover & {
        background-image: url($iconChevronHover);
    }
}

@mixin link-chevron-move-hover {
    transition: $ease-transition;

    a:hover &,
    button:hover & {
        transform: translateX(3px);
    }
}

@mixin link-chevron-large {
    background-size: 16px 28px;
    width: 16px;
    height: 28px;
    display: inline-block;
    background-image: url($iconChevronDefault);

    a:hover &,
    button:hover & {
        background-image: url($iconChevronHover);
    }
}

@mixin ease {
    transition: $ease-transition;
}

.ease {
    @include ease;
}

@mixin ease-out {
    transition: $ease-out-transition;
}

.ease-out {
    @include ease-out;
}

@mixin newsletter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(4, 61, 42);
    background: linear-gradient(0deg, rgba(4, 61, 42, 1) 0%, rgba(4, 61, 42, 0) 50%);
}
