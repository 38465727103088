.block--subscription {
    padding: 150px 0;
    background-image: linear-gradient(to right top, #003611, #003b14, #004117, #00461a, #004c1d, #095720, #146323, #1f6f25, #378525, #509b21, #6cb018, #8bc600);

    @media (min-width: 1024px) {
        padding: 150px 250px 150px 200px;
    }

    @media (min-width: 1200px) {
        padding: 150px 350px 150px 300px;
    }

    a {
        text-decoration: none;
        color: $white;
        font-weight: 400;
    }

    &--form {


        &--title {
            font-size: 36px;
            font-style: italic;
            color: $white;
            margin-bottom: 64px;
            line-height: 1.2;
        }

        &--text {
            color: $white;
        }

        &--button {
            margin-top: 30px;
            font-size: 15px;
        }

        &--policy {
            margin-top: 64px;
            font-size: 13px;

            a {
                font-weight: 300;
                text-decoration: underline;
            }
        }
    }

    &--form-wrap {
        max-width: 754px;
        margin: 0 auto;
    }

    input {
        border: 1px solid $white;
        width: 100%;
        background: transparent;
        height: 50px;
        color: $white;
        padding: 12px 20px;
        margin-bottom: 12px;
        border-radius: 10px;

        @include media-breakpoint-up (lg) {
            padding: 12px 152px 12px 20px;
            margin-bottom: 0;
            border-radius: 10px 20px 20px 10px;
        }

        &:active,
        &:focus,
        &:not(:placeholder-shown) {
            background: $white;
            color: $text-green;
            outline: none;
        }

        &::placeholder {
            color: $white;
            font-style: italic;
            font-size: 15px;
            font-weight: 250;
        }

        &.just-validate-error-field {
            color: $red-error;
            background: $white url(/theme/images/field-fail.svg) right 20px center no-repeat;
            padding: 12px 66px 12px 20px;

            @include media-breakpoint-up (lg) {
                padding: 12px 198px 12px 20px;
                background-position: right 150px center;
            }
        }
    }

    .just-validate-error-label {
        color: $white !important;
        margin-top: 10px;
        padding-left: 0;
        font-size: 13px;
        background: none;
    }

    &--form-input-wrapper {
        position: relative;
    }

    &--button {
        background-color: $text-green;
        border: 1px solid $text-green;
        color: white;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border-radius: 10px;

        @include media-breakpoint-up (lg) {
            width: 132px;
            height: 100%;
            border-radius: 0 10px 10px 0;
            position: absolute;
            top: 0;
            right: 0;
        }

        &:hover {
            background-color: $green-link-hover;
            border-color: $green-link-hover;
        }

        &--text {
            font-weight: 600;
        }
    }

    &--response-success,
    &--response-failure {
        border: 1px solid $white;
        width: 100%;
        border-radius: 10px;
        background: transparent;
        min-height: 50px;
        padding: 12px 20px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &--response-success {
        color: $green-link-hover;
        background: $white url(/theme/images/field-success.svg) right 20px center no-repeat;

        a {
            color: $green-link-hover;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &--response-failure {
        color: $red-error;
        background: $white url(/theme/images/field-fail.svg) right 20px center no-repeat;

        a {
            color: $red-error;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
