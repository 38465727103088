.block--two-column-heading-and-text {
    @include standard-spacing;

    &--line {
        border-bottom: 1px solid $bright-green;
        margin-bottom: 5rem;
    }

    &--content {
        &--title {
            margin-bottom: 1.5rem;
            color: $text-green;
            font-size: 36px;
            font-style: italic;
        }

        &--text {
            font-size: 15px;
        }
    }
}
