.masthead {
    background-color: $green-header;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    @include standard-spacing;


    &--video {
        position: absolute;
        object-fit: cover;
        width:100%;
        height:100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--title-section {
        margin-top: 250px;
    }

    &--title-section,
    &--statistics-section {
        position: relative;
    }

    &--statistics-section {
        z-index: 2;
    }

    &--title-section {

        &--title {
            font-size: 40px;
            line-height: 55px;
            color: $white;

            @include media-breakpoint-up (lg) {
                font-size: 70px;
                line-height: 75px;
            }

            em {
                color: $light-green;
                font-weight: 100;
            }

            p {
                font-weight: 100;
            }
        }

        &--subtitle {
            font-size: 15px;
            color: $white;
            line-height: 22px;

            @include media-breakpoint-up (sm) {
                margin-bottom: 120px;
            }

            @include media-breakpoint-up (lg) {
                font-size: 20px;
                line-height: 32px;
            }
        }

        &--video {
            margin-bottom: 120px;

            &--play-button {
                border: none;

                @include media-breakpoint-down (sm) {
                    padding-left: 0;
                }

                .play-icon {
                    background-image: url(#{$pathToImages}/play-button.svg);
                    display: inline-block;
                    background-size: 40px;
                    width: 40px;
                    height: 40px;
                    margin: 0;

                    @include media-breakpoint-up (sm) {
                        margin: auto;
                    }

                    @include media-breakpoint-up (lg) {
                        background-size: 80px;
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            &--text {
                @include media-breakpoint-down (sm) {
                    margin-top: 16px;
                }
            }

            &--modal {
                @include media-breakpoint-down (xl) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            &--modal-dialog {
                width: 100%;
                max-width: 591px;
                height: 332px;

                @include media-breakpoint-up (sm) {
                    max-width: 975px;
                    height: 548px;
                }

                @include media-breakpoint-up (md) {
                    max-width: 1000px;
                    height: 600px;
                }

                @include media-breakpoint-up (lg) {
                    max-width: 1151px;
                    height: 647px;
                }
            }

            &--modal-content {
                background-color: transparent;
                text-align: end;
                border: none;

                &--close-button {
                    border-radius: 100px;
                    font-family: inherit;
                    width: 35px;
                    height: 35px;

                    .close-icon {
                        background-image: url(#{$pathToImages}/green-close.svg);
                        display: inline-block;
                        background-size: 13px;
                        width: 13px;
                        height: 13px;
                    }
                }

                &--video {
                    width: 100%;
                    max-width: 591px;
                    height: 332px;

                    @include media-breakpoint-up (sm) {
                        max-width: 975px;
                        height: 548px;
                    }

                    @include media-breakpoint-up (md) {
                        max-width: 1000px;
                        height: 600px;
                    }

                    @include media-breakpoint-up (lg) {
                        max-width: 1151px;
                        height: 647px;
                    }
                }
            }
        }
    }

    &--statistics {
        color: $text-green;
        max-width: 100%;

        &--title {
            font-size: 12px;
        }

        &--number {
            font-size: 33px;
        }

        &--section {
            &--box {
                position: relative;

                @include media-breakpoint-down (xl) {
                    margin-inline: 0;
                }

                &::before {
                    background: $white;
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100vw;
                    height: 100%;
                    right: 50%;
                    top: 0;
                    z-index: 1;

                    @include media-breakpoint-down (xl) {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-down (xl) {
                width: 100%;
                max-width: 100%;
                padding: 0;
            }


        }

        .statistics {
            background: white;
            padding-block: 3.5rem 2rem;
            border-radius: 0 2rem 0 0;

            @include media-breakpoint-down (xl) {
                border-radius: 2rem 2rem 0 0;
            }

            .stat {
                z-index: 2;

                &:nth-child(odd) {
                    @include media-breakpoint-down (sm) {
                        padding-right: 0;
                    }
                }

                &:nth-child(even) {
                    @include media-breakpoint-down (sm) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.modal-open {
    padding-right: 0 !important
}
