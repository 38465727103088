.block--news-contact {
   @include full-light-green-background-padded;

    &--image {
        width: 73px;

        @include media-breakpoint-up (sm) {
            width: 79px;
        }

        @include media-breakpoint-up (lg) {
            width: 143px;
        }

        @include media-breakpoint-up (xl) {
            width: 171px;
        }
    }
}
