.block--two-column-image-and-text {
    @include standard-spacing;
    width: 100%;

    &--section {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        @include media-breakpoint-up(xl) {
            align-items: center;
        }

        &--image-tile {
            z-index: 1;
            margin-right: 1.5rem;

            @include media-breakpoint-up(lg) {
                margin-top: 5rem;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 0;
            }

            &--image {
                border-radius: 0 40px 40px 0;
                max-width: 100%;
                width: 550px;

                @include media-breakpoint-up(lg) {
                    width: 950px;
                }

                &.right-image {
                    border-radius: 40px 0 0 40px;
                }
            }

            &.left-box-spacing {
                margin-top: 3rem;
            }

            &.right-box-spacing {
                margin-right: 0;
                padding-left: 1.5rem;
                margin-left: auto;

                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
            }
        }

        &--text-tile {
            width: 100%;
            justify-content: center;
            margin-top: -220px;
            min-height: 680px;
            padding: 200px 1.5rem 0 1.5rem;
            font-weight: 300;

            &.left-box {
                border-radius: $standard-border-radius 0 0 $standard-border-radius;
            }

            &.right-box {
                border-radius: $standard-border-radius 0 0 $standard-border-radius;

                @include media-breakpoint-up(lg) {
                    border-radius: 0 $standard-border-radius $standard-border-radius 0;
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: -80px;
                    padding: 0 100px 0 50px;
                }

                @include media-breakpoint-up(xl) {
                    width: 80%;
                    min-height: 980px;
                    padding: 80px;
                }
            }

            @include media-breakpoint-up(sm) {
                margin-top: -280px;
                padding: 250px 8.33333333% 0 8.33333333%;
            }

            @include media-breakpoint-up(lg) {
                border-radius: 40px 0 0 40px;
                margin-top: 0;
                margin-left: -80px;
                padding: 0 50px 0 100px;
            }

            @include media-breakpoint-up(xl) {
                width: 80%;
                min-height: 980px;
                padding: 2vw 5vw;
                display: flex;
                align-items: center;
            }

            a {
                text-decoration: none;
            }

            &--icon,
            &--text {
                color: $white;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            &--icon {
                width: 20px;
                height: 20px;
                margin-top: 2px;
            }

            &--content {
                padding-block: 4rem;

                @include media-breakpoint-up(sm) {
                    padding-inline: 1.5rem;
                    padding-block: 5rem;
                }

                @include media-breakpoint-up(xl) {
                    padding: 100px;
                }

                &--heading {
                    font-size: 30px;
                    font-style: italic;
                    font-weight: 300;
                    margin-bottom: 30px;

                    @include media-breakpoint-up(lg) {
                        font-size: 36px;
                    }
                }

                &--contact {
                    color: $white;
                    margin-bottom: 10px;

                    a {
                        text-decoration: none;
                    }
                }

                &--text {
                    font-size: 15px;
                    margin-bottom: 30px;
                    font-weight: 300;

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 50px;
                    }

                    h6 {
                        color: $text-green;
                    }
                }

                &--footnote {
                    margin-top: 3rem;
                    font-size: 13px;
                    color: $black;
                    opacity: .7;

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 50px;
                    }
                }

            }

            &.light-green {
                background-color: $light-green-background;

                .heading-colour {
                    color: $text-green;
                }

                .text-colour {
                    color: $dark-grey;
                }
            }

            &.dark-green {
                background-color: $green-header;

                .heading-colour, .text-colour {
                    color: $white;
                }
            }
        }
    }
}
