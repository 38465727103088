.footer {
    background-color: $text-green;
    color: $white;
    padding-block: 4rem;
    font-weight: 300;

    .font-18 {
        font-size: 18px;
    }

    a {
        color: $white;
        text-decoration: none;
    }

    &--logo {
        width: 233px;
        height: 63px;
    }

    &--links-section {

        &--menu {

            &--item {
                color: $white;
            }

            &--link {
                &:hover {
                    color: $bright-green;
                }
            }

            &--chevron {
                @include link-chevron;
                @include ease;

                margin-left: 8px;
                background-image: url($iconChevronWhite);

                a:hover &,
                button:hover & {
                    transform: translateX(3px);
                }
            }
        }

        &--logo {
            @include ease;

            margin-bottom: 30px;

            @include media-breakpoint-up (lg) {
                margin-bottom: 0;
            }
        }

        &--social {
            @include ease;

            text-align: left;

            &--link {
                text-decoration: none;

                img {
                    @include ease;
                }

                &:hover {
                    img {
                        opacity: 0.8;
                        transform: scale(1.1, 1.1);
                    }
                }
            }

            &--icon {
                margin-inline: 6px;
            }

            @include media-breakpoint-up (lg) {
                text-align: center;
            }
        }
    }

    &--text {
        @include ease;

        opacity: 0.7;
        margin-block: 3rem;
        font-size: 19px;

        @include media-breakpoint-up (lg) {
            margin-block: 5rem;
        }

        @include media-breakpoint-up (xl) {
            margin-block: 6rem;
            font-size: 22px;
        }
    }

    &--disclaimer {
        @include ease;

        margin-bottom: 3rem;

        p:last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: 600;
        }

        @include media-breakpoint-down (lg) {
            margin-block: 4rem 3rem;
        }

        &--nsw {
            width: 59px;
            flex: 0 0 59px;
            margin-right: 37px;

            img {
                width: 59px;
                height: 80px;
            }
        }

        &--content {
            flex: 1 1 auto;
            opacity: 0.7;
        }
    }

    &--bottom {
        &--links {
            display: block;
            margin-left: auto;
            font-size: 14px;
            opacity: 0.7;

            a {
                @include ease;

                margin-inline: 0 40px;
                color: $white;

                @include media-breakpoint-up (lg) {
                    margin-inline: 40px 0;
                }

                &:hover {
                    color: $green-link-hover;
                }
            }
        }

        &--text {
            margin-right: 20px;
            font-size: 14px;
            opacity: 0.7;
        }
    }
}
