.cta-stack {
    $block: &;

    &--item {
        margin-bottom: 20px;

        @include media-breakpoint-up (xl) {
            margin-bottom: 10px;
        }
    }

    &--link {
        text-decoration: none;
        font-size: 15px;
        font-weight: 400;
        color: $text-green;

        &:hover {
            color: $green-link-hover;
        }
    }

    &--chevron {
        @include link-chevron;
        @include link-chevron-move-hover;

        margin-left: 8px;
    }

    &.white-link {
        #{$block}--link {
            color: $white;

            &:hover {
                color: $green-link-hover;
            }
        }

        #{$block}--chevron {
            background-image: url($iconChevronWhite);
        }

        #{$block}--link:hover #{$block}--chevron {
            background-image: url($iconChevronHover);
        }

    }
}
