$weights: 100, 200, 300, 400, 500, 600, 700, 800;

@each $weight in $weights {
    .font-weight-#{$weight} {
        font-weight: $weight !important;
    }
}

h1,
.h1 {
    @include ease;

    color: $text-green;
    font-style: italic;
    line-height: 1.08;
    font-size: 40px;
    margin-bottom: 24px;

    @include media-breakpoint-up (lg) {
        font-size: 55px;
        margin-bottom: 48px;
    }

    @include media-breakpoint-up (xl) {
        font-size: 70px;
    }

    .dark-green & {
        color: $white;
    }
}

h2,
.h2 {
    @include ease;

    color: $text-green;
    font-style: italic;
    font-weight: 300;
    line-height: 1.2;
    font-size: 30px;
    margin-bottom: 20px;

    @include media-breakpoint-up (xl) {
        font-size: 36px;
        margin-bottom: 32px;
    }

    .dark-green & {
        color: $white;
    }
}

h3,
.h3 {
    @include ease;

    color: $text-green;
    font-style: italic;
    font-weight: 300;
    line-height: 1.2;
    font-size: 22px;
    margin-bottom: 20px;

    @include media-breakpoint-up (xl) {
        font-size: 22px;
        margin-bottom: 32px;
    }

    .dark-green & {
        color: $white;
    }
}

h4,
.h4 {
    @include ease;

    color: $text-green;
    font-style: normal;
    font-weight: 600;
    line-height: 1.7;
    font-size: 15px;
    margin-bottom: 12px;

    .dark-green & {
        color: $white;
    }
}

.entry {

    ul,
    ol {
        margin: 0;
        padding-left: 1.1rem;

        li {
            margin-bottom: 4px;

            &::marker {
                color: $bright-green;
            }

            .dark-green & {
                color: $white;
            }
        }
    }

}
