.block--form {
    @include full-light-green-background-padded;

    &--heading {
        color: $text-green;
    }

    &--text-section {
        margin-bottom: 32px;

        &--heading {
            color: $text-green;
            font-weight: 400;
        }

        &--icon {
            padding-right: 16px;
            margin-bottom: auto;
        }

        &--text {
            a {
                color: $text-black;
                text-decoration: none;
            }
        }
    }

    &--form-title {
        color: $text-green;
        font-weight: 400;
    }

    input {
        height: 50px;
        margin-top: 1.5rem;
    }

    input,
    textarea {
        margin-top: 1.5rem;
        border: 1px solid $text-green;
        width: 100%;
        background-color: transparent;
        color: $text-green;
        padding: 12px 20px;
        border-radius: 8px;

        &:focus {
            outline: none;
            border-color: $bright-green;
        }

        &.just-validate-error-field{
            color: $red-error;
            background: url(#{$pathToImages}/field-fail.svg) right 10px top 10px no-repeat;
        }
    }

    textarea {
        &::-webkit-scrollbar {
            border: none;
        }

        &::-webkit-scrollbar-track {
            border: 2px solid $text-green;
            border-radius: 100px;
            margin: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $text-green;
            border-radius: 100px;
        }
    }

    ::placeholder {
        font-style: italic;
    }

    .just-validate-error-label {
        color: $red-error!important;
        margin-top: 5px;
        font-size: 13px;
    }

    &--button {
        margin-top: 1.625rem;
        background-color: $bright-green;
        border: 1px solid $bright-green;
        color: white;
        padding: 12px 40px;
        border-radius: 8px;

        &--text {
            font-weight: 600;
        }

        &:hover {
            background: linear-gradient(263deg, #005320 20.31%, #77C600 99.08%), #043D2A;
        }
    }

    &--bottom-text {
        a {
            color: $text-black;
        }
    }
}
