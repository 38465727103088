/*
  path to images
 */
$pathToImages: '/theme/images';

/*
  Colors
 */
$black: #000;
$dark: #111111;
$text-black: #2c2c2c;
$white: #fff;
$text-green: #043D2A;
$light-green: #7DB603;
$green: #103c2c;
$light-green-background: #F4F9F4;
$dark-grey: #252525;
$bright-green: #0D8924;
$green-link-hover: $bright-green;
$red-error: #E90C0C;
$green-header: #033d2a;
$social-text-colour: #1C1E21;
$social-facebook-blue: #1a77f2;

/*
  Block Margins
 */
$block-margin-default: 120px;
$block-margin-tall: 180px;
$block-margin-short: 50px;
$block-margin-mobile-default: 60px;
$block-margin-mobile-tall: 90px;
$block-margin-mobile-short: 40px;
$hero-text-margin-adjust: 20px;
$contact-office-bottom-margin-adjust: 20px;
$about-grid-bottom-margin: 80px;
$about-grid-bottom-margin-mobile: 30px;

$container-fluid-max-width: 1800px;
$input-focus-box-shadow: 0px 3px 0px 0px #999;

$standard-border-radius: 32px;
$half-border-radius: calc($standard-border-radius / 2);

/*
  Bootstrap overrides
*/
$primary: #103c2c;
$green: #103c2c;
$success: #7DB603;
$gray-500: #b6b6b6;
$gray-600: #686868;
$light: #f8f7f7;
$dark: #111111;

$link-hover-color: $link-color;
$link-hover-decoration: none;

$grid-gutter-width: $spacer;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$text-muted: $gray-500;

$iconChevronDefault: '/theme/images/chevron-green.svg';
$iconChevronHover: '/theme/images/chevron-hover.svg';
$iconChevronWhite: '/theme/images/chevron-white.svg';

$ease-transition: all 0.25s ease;
$ease-out-transition: all 0.25s ease;
