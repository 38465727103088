.block--previous-newsletters {
    @include standard-spacing;

    .newsletters {
        margin-top: 48px;

        &--heading {
            @include ease;

            color: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 32px;
            width: 100%;
        }

        &--image {
            @include ease;

            position: relative;

            &--overlay {
                @include newsletter-overlay;
            }
        }

        &--link {
            @include ease;

            border-radius: $standard-border-radius;
            background-color: $text-green;
            overflow: hidden;
            text-decoration: none;
            position: relative;

            &:hover {
                .newsletters--image {
                    opacity: 0.3;
                    transform: scale(1.05, 1.05);
                }
            }
        }

        .carousel-desktop .carousel--track {
            margin-bottom: 48px;
        }

        .carousel-mobile .newsletters--newsletter {
            margin-bottom: 48px;
        }
    }
}
