.hero-text {
    @include standard-spacing;

    &--text {
        font-weight: 300;
        color: $text-green;
        font-size: 15px;
        line-height: 25px;

        @include media-breakpoint-up (lg) {
            font-size: 20px;
            line-height: 32px;
        }
    }
}
