.block--title-hero-text-and-hero-cta {
    @include standard-spacing;

    &--title {
        font-style: italic;
        font-weight: 100;
    }

    &--text {
        font-size: 15px;
        color: $dark-grey;
    }

    &--items {
        margin-top: 50px;
    }

    &--item {
        margin-bottom: 20px;
    }

    &--link {
        text-decoration: none;
        font-size: 15px;
        font-weight: 400;
        color: $text-green;

        &:hover {
            color: $green-link-hover;
        }
    }

    &--chevron {
        @include link-chevron;
        @include link-chevron-move-hover;

        margin-left: 8px;
    }
}
