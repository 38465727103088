.icon-and-text-grid {
    @include standard-spacing;

    &--item {
        margin-bottom: 50px;

        @include media-breakpoint-up (lg) {
            margin-bottom: 75px;
        }

        &--heading {
            font-size: 20px;
            color: $text-green;
            font-style: italic;
            font-weight: 300;
            margin-top: 30px;
            margin-bottom: 10px;
        }

        &--text {
            font-size: 15px;
            color: $dark-grey;
            line-height: 25px;
        }

        &--image {
            width: 58px;
            height: 50px;
        }
    }
}
