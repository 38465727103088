.block--latest-newsletter {
    @include standard-spacing;
    $prefix: &;

    &--title {
        padding: 80px 24px 0 24px;

        @include media-breakpoint-up (sm) {
            padding: 32px 0 32px 32px;
        }

        @include media-breakpoint-up (lg) {
            padding: 64px 0 64px 64px;
        }

        @include media-breakpoint-up (xl) {
            padding: 48px 0 48px 48px;
        }

        &--text {
            @include ease;

            color: $white;
            font-style: italic;
            font-weight: 250;
            line-height: 1.2;
            font-size: 30px;

            @include media-breakpoint-up (lg) {
                font-size: 55px;
            }
        }
    }

    &--image-wrapper {
        padding: 24px 24px 0 24px;

        @include media-breakpoint-up (sm) {
            padding: 32px 32px 0 0;
        }

        @include media-breakpoint-up (lg) {
            padding: 64px 48px 0 0;
        }
    }

    &--image {
        @include ease;

        position: relative;
        transform-origin: bottom center;

        &--overlay {
            @include newsletter-overlay;
        }
    }

    &--link {
        @include ease;

        background-color: $text-green;
        border-radius: $standard-border-radius;
        text-decoration: none;

        &:hover {

            #{$prefix}--image {
                opacity: 0.5;
                transform: scale(1.02, 1.02);

                @include media-breakpoint-up (sm) {
                    transform: scale(1.05, 1.05);
                }
            }
        }
    }
}
