.block--video-and-cta {
    @include standard-spacing;
    padding-left: 20px;
    padding-right: 20px;

    &--subheading {
        font-size: 15px;
        font-weight: 400;
        color: $text-green;
        margin-bottom: 3rem;
    }

    &--embed-video {
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%
        }
    }
}
