.contact-feedback {
    .failure-heading {
        color: $red-error;
    }

    .success-heading {
        color: $bright-green;
    }

    .failure-heading, .success-heading {
        margin-bottom: 0.5rem;
    }

    .success-message, .failure-message {
        @include alternative-spacing;
    }
}
