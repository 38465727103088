.carousel {
    &-arrows {
        $arrowsClass: &;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        &--arrow {
            @include ease;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            outline: none;
            border: 1.5px solid $text-green;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                border-color: $green-link-hover
            }
        }

        &--chevron {
            @include link-chevron-large;
            @include ease;
        }

        &--arrow-prev {
            margin-right: 16px;

            #{$arrowsClass}--chevron {
                transform: rotate(180deg);
                margin-left: -2px;
            }

            &:hover #{$arrowsClass}--chevron {
                margin-left: -5px;
            }
        }

        &--arrow-next {
            #{$arrowsClass}--chevron {
                margin-right: -2px;
            }

            &:hover #{$arrowsClass}--chevron {
                margin-right: -5px;
            }
        }
    }

}
