.timeline {
    $block: &;

    &--number {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.875rem;
        line-height: 23px;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 100px;

        &--active {
            background-color: $bright-green;
            border-color: $bright-green;
            color: $white;
        }
    }

    &--item {
        &--text {
            margin: 0 0 1.5rem 1.5rem;
        }

        &:last-of-type #{$block}--item--text {
            margin: 0 0 0 1.5rem;
        }

        &--time-period {
            color: rgba(0, 0, 0, 0.7);
            font-size: .75rem;
            line-height: 1.6;
            font-weight: 300;
        }

        &--line {
            width: 1px;
            background: $bright-green;
            height: 100%;
            margin: 0 auto;
        }
    }

    &--timeline-title {
        font-size: 22px;
        font-style: italic;
        color: $text-green;
        margin-bottom: 20px;
    }

    &--bottom-text {
        color: $text-green;
        font-size: 14px;
        margin-top: 32px;
    }
}
