.block--social-feed {
    @include standard-spacing;
    $block: &;

    &--text-content {
        margin-bottom: 48px;
    }
}

.crt-override .crt {
    &-widget-waterfall .crt-feed {
        width: calc(100% + 20px);
        margin-left: -10px;
    }

    &-post {
        .crt-post-border {
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.17);
            padding: 12px;
            display: flex;
            flex-direction: column;
        }

        .crt-post-header {
            $icon-width: 32px;
            order: 1;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            margin-bottom: 16px;

            .crt-post-fullname {
                order: 1;
                flex: 1 1 calc(100% - $icon-width);
                display: flex;
                align-items: center;
                text-align: left;
                margin-bottom: 0;

                a {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.33;
                    color: $social-text-colour;
                    text-decoration: none;
                    text-align: left;

                    &:hover {
                        color: $bright-green;
                    }
                }
            }

            .crt-social-icon {
                order: 2;
                flex: 0 0 $icon-width;
                margin-bottom: 0;
                background-color: $social-facebook-blue;
                width: $icon-width;
                height: $icon-width;
                border-radius: 50%;
                padding-top: 7px;

                svg {
                    color: $white;
                    transform: scale(1.3);
                }
            }

            .crt-post-title {
                order: 3;
                flex: 0 0 100%;
                color: $social-text-colour;
                margin: 8px 0 0;
                padding: 0;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 1.33;
                text-decoration: none;
                text-align: left;
            }
        }

        .crt-post-content {
            order: 2;
            margin-bottom: 16px;
        }

        .crt-post-text {
            order: 3;
            text-align: left;
            padding: 0;
            color: $social-text-colour;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.33;
        }

        .crt-post-footer {
            display: none;
        }
    }
}
